import React from 'react'
import Core from '../templates/Core'
import styled from "styled-components";
import Header from '../page_fragments/Header'
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import GatsbySvg from '../assets/svg/Gatsby_Monogram.svg';
import LambdaLogo from '../assets/svg/lambda_logo.svg';
import A11y from  '../assets/svg/a11y.svg';
import AWS from '../assets/svg/Amazon_Web_Services_Logo.svg';
import Netlify from '../assets/svg/Netlify_logo.svg';
import Node from '../assets/svg/Node.js_logo.svg';
import ReactLogo from '../assets/svg/React-icon.svg';
import ServerlessLogo from '../assets/svg/serverless.svg'
import JSLogo from '../assets/svg/JSlogo.svg'

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
color: ${props => props.theme.textColor};
text-align: center;
width:100%
`

const ParaWrap = styled.div`
text-align: left;
padding: 1em;
margin: auto;
`

const LogoBox = styled.div`
display:flex;
flex-direction: row;
flex-wrap:wrap;
`
const LogoWrap = styled.img`
width:200px;
display:block;
margin: auto;
`
const LogoLink = styled.a`
flex:1;
width: 100%;
padding: 1em;
margin: auto;
`

export default ({ data }) => (
  <Core>
  <Helmet>
    <title>Justin Isbell-About</title>
    <meta charSet="utf-8" />
    <meta name="description" content="Read about Justin Isbell and get access to his Resume"></meta>
    <link rel="canonical" href="https://www.justinisbell.org/about" />
  </Helmet>
  <Header />
  <PageWrapper>
  <h1>About</h1>
  <main>
  <Img fluid={data.file.childImageSharp.fluid} alt="Picture of Justin Working at his Desk" />
<ParaWrap>
  <p>
    I'm Justin Isbell. I lead software engineering  Hagerty, an Automotive Enthusiast and Insurance brand, for their 
    core services domain. This domain encompases our customer master data management, identity management, payments and billing systems. 
    All in all this means the core services  enable many capabilities for the rest of the organization. We build on a variety of cloud services and 
    infrastructure patterns. 
  </p>
  <p>
    I am the father of 2 wonderful daughters and married to my wonderful wife Kira. Kira and I are both avid Formula 1 fans and compete in triathlons together.
    I enjoy reading just about anything I can get my hands on and am a lifelong learner. Currently I am taking an Organizational Leadership course through Coursera.

  </p>

  </ParaWrap>
  <h2>Skills</h2>
                <p>I'm familiar with more than this list but these are particular technologies I have enjoyed working with.</p>
                <LogoBox>
                <LogoLink href='https://www.gatsbyjs.org/'><LogoWrap title="Gatsby" alt="Gatsby Js" src={GatsbySvg}></LogoWrap></LogoLink>
                <LogoLink href='https://aws.amazon.com/lambda/'><LogoWrap title="AWS Lambda" alt="AWS Lambda" src={LambdaLogo}></LogoWrap></LogoLink>
                <LogoLink href='https://www.netlify.com/'><LogoWrap title="Netlify" alt="Netlify" src={Netlify}></LogoWrap></LogoLink>
                <LogoLink href='https://aws.amazon.com/'><LogoWrap title="AWS" alt="AWS" src={AWS}></LogoWrap></LogoLink>
                <LogoLink href='https://nodejs.org/en/'><LogoWrap title="NodeJS" alt="NodeJS" src={Node}></LogoWrap></LogoLink>
                <LogoLink href='https://reactjs.org/'><LogoWrap title="React" alt="React" src={ReactLogo}></LogoWrap></LogoLink>
                <LogoLink href='https://www.serverless.com/'><LogoWrap title="Serverless Framework" alt="Serverless" src={ServerlessLogo}></LogoWrap></LogoLink>
                <LogoLink href='https://developer.mozilla.org/en-US/docs/Web/JavaScript'><LogoWrap title="Javascript" alt="Javascript" src={JSLogo}></LogoWrap></LogoLink>
                </LogoBox>
  </main>
  </PageWrapper>
</Core>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`